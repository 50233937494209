import React from 'react'
import { text } from "../../config"
import "./Header.css"




export default function Header() {
    return (
        <div className="Header">
            <img src='logo.png'/>
            <h1>{text.home.headline}</h1>
            <p>{text.home.title}</p>
        </div>
    )
}