let env = {
    backend: !process.env.REACT_APP_BACKEND  ? "" : process.env.REACT_APP_BACKEND,
    domain_name: "https://hotcandidatetakes.com"
}

let text = {
    general : {
        comment_email: "comments@hotcandidatetakes.com",
        new_takes: "takes@hotcandidatetakes.com"
    },

    filter: {
        header: "Search and Filter",
        tag_header: "Hot Tags"
    },

    home: {
        headline: "Hot Candidate Takes",
        title: "Search your candidate and hear them out. Before you cast your ballot, do you agree?",
        why: "This artbook is designed to be a permanent digital archive, serving as an enduring reminder of pivotal historical events and statements. This artbook not only educates but also encourages ongoing reflection and critical analysis, offering proof of the events and words that have shaped US politics. May it be a reference point. Use it to support or challenge viewpoints."

    },
    footer: {
        header: "Non Bias - Join the Conversation: Share a Candidate's Boldest Views with Us and We will share with the world",
        form_label: "Tell us about the latest hot takes",
        contest: "You have found a false information ? Contest it via email contest@hotcandidatetakes.com",
        textarea_placeholder: "Excercise your freedom of speech. Stay Informed: Get the Latest Updates on Hot News Stories and Share Insights with Your Community!"
    }
}


export {text, env}