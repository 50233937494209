import React, { useState }  from "react";
import { text } from "../../config"
import "./Footer.css"
import "../../requests"



export default function Footer() {

    const [message, setMessage] = useState("")
    const [textarea_message, setTextAreaMessage] = useState("")

    return (
        <div className="Footer">
            <h3> {text.footer.header} </h3>
            <p>{`For Comments: ${text.general.comment_email}`}</p>
            <p>{`Share your hot takes: ${text.general.new_takes}`}</p>
            <a href={`mailto: ${text.footer.contest}`}>{text.footer.contest}</a>
            <p>Hot Candidate Takes. All Rights reserved.</p>
        </div>
    )
}