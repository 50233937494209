import axios from 'axios';

let req = {
    post: (url, data ) => {
        axios.post(url, data ).then(function (response) {
                console.log(response)
            }).catch((err)=> {
                throw err
            })
    },

    get: (url) => axios.get(`${url}`)
    .then(response => {
        return(response.data)
    })
    .catch(err => {
        throw err
    })
}


export { req }


