import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';
import FilteredHome from "./components/FilteredHome/FilteredHome";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Home /> } /> {/* 👈 Renders at /app/ */}
        <Route path=":shareid" element={ <FilteredHome /> } /> {/* 👈 Renders at /app/ */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
