import React, { useEffect, useState }  from "react";
import "./Home.css"
import ReactPlayer from 'react-player'
import { RWebShare } from "react-web-share";
import AliceCarousel, { Link } from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';

import { text, env} from "../../config"
//import { database } from "../../database"

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { req } from "../../requests";
import Filter from "../Filter/Filter";
import Comments from "../Comments/Comments";
import { DatabaseContext } from "../Context/CommonContext";

let played = (id)=> {
    req.get(`${env.backend}/played/${id}`)
}

let playedFinish = (id)=> {
    req.get(`${env.backend}/played/end/${id}`)
}


let get_random_number = (start,end)=> {
    return Math.floor(Math.random() * (end - start + 1) + start)
}   


const responsive = {
    0: { items: 1 }
};

export default function Home() {

    const [database, setDatabase] = useState([])
    const [dataCounter, setDataCounter] = useState(0)
    const [filter, setFilter] = useState(undefined)


    useEffect(()=> {

        let set_general_data = async () => {
            await req.get(`${env.backend}/home/data/50/${dataCounter}`)
            .then((data)=> {
                if ( data ) {
                    let unordered_data = []
                    while ( data.length != 0 ) {
                        let n = get_random_number(0, data.length - 1)
                        unordered_data.push(data[n])
                        data.splice(n, 1)
                    }
                    setDatabase(unordered_data)

                }
                
            }).catch(()=> {
                setDatabase([])
            })
        }

        let filter_data = async() => {
            console.log(`filtering to ${filter}`)
            await req.get(`${env.backend}/filter/${filter}`)
            .then((data)=> {
                setDatabase(data)
            })
            .catch(()=> {
                setDatabase([])
            })
        }

        if (!filter || filter=="Reset") {
            console.log("general")
            set_general_data()
        } else {
            filter_data()
        }
        
    
        
    }, [dataCounter, filter])

    return (
        <div className="Home">
            
            <Header />
            <DatabaseContext.Provider value={{filter,setFilter}}>
                <Filter />
            </DatabaseContext.Provider>
            

            <div className="gallery">
               {
                database.map(item => {
                    if (item.type == "video") {
                        return (
                            <div key={item.id} className="content">
                                <h3>{item.title}</h3>
                                <em>{item.location}</em>
                                <p>{item.description}</p>
                                <ReactPlayer
                                    url={item.url}
                                    controls = { true }
                                    width='100%'
                                    height='auto'
                                    onStart={()=> played(item.id)}
                                    onEnded={()=> playedFinish(item.id)}
                                    />
                                <p className="credit">{item.credit}</p>
                                <h3>Critics Say :</h3>
                                <p>{item.take}</p>
                                <h3>Another Perspective : </h3>
                                <p>{item.defense}</p>

                                <RWebShare
                                    data={{
                                        text: `${item.title}`,
                                        url: `${env.domain_name}/${item.id}`,
                                        title: "Hot Politico Takes",
                                        }}
                                        onClick={() => console.log("shared successfully!")}>
                                        <a className="share"><span className="material-symbols-outlined">share</span>Share</a>
                                </RWebShare>

                                <Comments id={item.id} />
    
                            </div>
                        )
                    } else if (item.type == "image") {
                        return (
                            <div key={item.id} className="content">
                                <h3>{item.title}</h3>
                                <em>{item.location}</em>
                                <p>{item.description}</p>
                            
                                <AliceCarousel
                                    mouseTracking
                                    items={item.url.map((item) => <img src={item} />)}
                                    responsive={responsive}
                                    controlsStrategy="alternate"
                                />

                                <h3>Critics Say :</h3>
                                <p>{item.take}</p>
                                <h3>Another Perspective : </h3>
                                <p>{item.defense}</p>

                                <RWebShare
                                    data={{
                                        text: `${item.title}`,
                                        url: `${env.domain_name}/${item.id}`,
                                        title: "Hot Politico Takes",
                                        }}
                                        onClick={() => console.log("shared successfully!")}>
                                        <a className="share"><span className="material-symbols-outlined">share</span>Share</a>
                                </RWebShare>

                                <Comments id={item.id} />
                            </div>
                            
                        )
                    }
                })
               }
            </div>
            <div className="about">
                <h3>What the heck is this website?</h3>
                <p>{text.home.why}</p>
            </div>

            <Footer />
           

        </div>
    )
}