import React, { useContext, useEffect, useState } from "react";
import { req } from "../../requests";
import { text, env } from "../../config";
import "./Filter.css"
import { DatabaseContext } from "../Context/CommonContext";





export default function Filter(){

    const [filterList, setFilterList] = useState(["Reset", "Election"])
    const [searchValue, setSearchValue] = useState("")
    const {filter, setFilter} = useContext(DatabaseContext)

    let activeStatus = (id)=> {
        if (filter == id) {
            return "active"
        } else {
            return "notActive"
        }
    }

    let updateFilter = (f)=> {
        setFilter(f)
    }   

    let updateFilterList = async () => {
            await req.get(`${env.backend}/tags/hot`)
            .then((data)=> {
                setFilterList(data)
            })
            .catch(()=> {
                console.log("Setting Standard Hot Tags")
                setFilterList(["Reset","Trump", "Biden", "Missouri"])
            })
        }

    useEffect(()=> {
        console.log("Loading Filters")
        
        if (filter)
            console.log(`Updating to ${filter}`) // Just confirmation. Does not do anything

       if (filterList.length < 3) {
            updateFilterList()
       }

    }, [filter])

    return (
        <div className="Filter">

            <div className="searching">
                <label><h3>{text.filter.header}</h3></label>
                <div className="filter"><input value={searchValue} onChange={(ev)=> {setSearchValue(ev.target.value)}} placeholder="Search by Candidate, city, state" /><a onClick={()=> {
                    updateFilter(searchValue)
                    setSearchValue("")
                }} className="button">Search</a></div>
                <h4>{text.filter.tag_header}</h4>
                <ul>
                {
                    filterList.map(item => {
                        return (
                            <li key={item} className={activeStatus(item)}
                                onClick={()=> {
                                setFilter(item)
                            }}>{item}</li>
                        )
                    })
                }

                </ul>
            </div>

        </div>
    )
}