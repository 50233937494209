import { React, useEffect, useState } from  "react"
import { req } from "../../requests";
import { text, env} from "../../config"
import "./Comment.css"




export default function Comments(props) {

    const id = props.id
    let [comments, setComments] = useState([])
    let [commentValue, setCommentValue] = useState("")

    let get_comments = async () => {
        await req.get(`${env.backend}/comments/${id}`)
        .then(data => {
            if ( data.length < 1 ) {
                setCommentValue([])
                return
            }
            setComments(data)
            console.log(`${id} - ${data}`)
        }).catch(()=> {
            console.log(`Could not fetch comment for ${id}`)
        })

    }

    let post_comment = () => {
        comments.push(commentValue)
        req.post(`${env.backend}/comments`, {id: id, text: commentValue})
        setCommentValue("")
    }

    useEffect(()=> {

        get_comments()

    }, [])



    return (

        <div className="Comments">
            <div className="list">
                <h3>Comments: </h3>
               {
                comments.map((item) => {
                    return (
                        <p>{item}</p>
                    )
                } )
               }
            </div>
            <textarea value={commentValue} onChange={(event)=> setCommentValue(event.target.value)} placeholder="Submit your Anon Comment" />
            <a className="button" onClick={()=> post_comment()}>Speak Free</a>
        </div>
    )
}