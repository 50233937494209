import React, { useEffect, useState }  from "react";
import ReactPlayer from "react-player";
import { env } from "../../config";
import { req } from "../../requests";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useParams } from "react-router-dom";
import "./FilteredHome.css"





export default function FilteredHome(){

    const [database, setDatabase] = useState([])
    const { shareid } = useParams()


    useEffect(()=> {

        let set_filtered_data = async() => {
            await req.get(`${env.backend}/share/${shareid}`)
            .then((data)=> {
                setDatabase(data)
            })
            
        }

        if (shareid) {
            set_filtered_data()
        } else {
            //return them home
        }

        console.log()
    }, [shareid])

    return (
        <div className="FilteredHome">
            <Header />

            <div className="gallery">
               {
                database.map(item => {
                    if (item.type == "video") {
                        return (
                            <div key={item.id} className="content">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <ReactPlayer
                                    url={item.url}
                                    controls = { true }
                                    width='100%'
                                    height='auto'
                                    />
                                <p>{item.take}</p>
                                <h3>Another Perspective: </h3>{item.defense}
                                <textarea placeholder="Submit your Anon Comment" />
                                <a className="button">Speak Free</a>
    
                            </div>
                        )
                    } else if (item.type == "image") {
                        return (
                            <div key={item.id} className="content">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <img src={item.url} />
                                <p>{item.take}</p>
                                <h3>Another Perspective: </h3>{item.defense}
                                <textarea placeholder="Submit your Anon Comment" />
                                <a className="button">Speak Free</a>
                            </div>
                            
                        )
                    }
                })
               }
            </div>

            <Link to="/">Watch More</Link>

            <Footer />
        </div>
    )
}